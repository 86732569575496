import React from 'react';
import classNames from 'classnames';
import { Check, Clear } from '@/icons';

interface TicketConditionLineProps {
  negative?: boolean;
  className?: string;
  iconClasses?: string;
}

const TicketConditionLine: React.FC<TicketConditionLineProps> = ({
  children,
  negative,
  className,
  iconClasses,
}) => {
  const Icon = negative ? Clear : Check;

  return (
    <li
      className={classNames(
        'flex items-start gap-1',
        negative ? 'text-secondary-red' : 'text-secondary-green',
        className,
      )}
    >
      <Icon className={classNames('w-3 h-3 fill-current flex-shrink-0', iconClasses)} />
      {children}
    </li>
  );
};

export default TicketConditionLine;
