import React from 'react';
import classNames from 'classnames';

type PageProps = Pick<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className'>;

/**
 * Wrapper pro obsah stránek
 * Nastavuje šířku containeru a odsazení stránky dole
 */
const Page: React.FC<PageProps> = ({ children, className }) => (
  <div role="main" className={classNames('page-layout pb-28', className)}>
    {children}
  </div>
);

export default Page;
