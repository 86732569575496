import { useTranslation } from 'next-i18next';
import React from 'react';
import { VehicleKey, VehicleType } from '@web/shop-logic/dist/hooks/types';
import { Line as ILine } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import Carrier from '@/components/connection/Carrier';
import Timetable from '@/components/connection/Timetable';
import useModal from '@/components/modal/useModal';
import { ShopFaresImageEntity } from '@/models/types';

interface LineProps {
  arrivalCityName?: string;
  departureCityName?: string;
  fromStationId: number;
  lineProps: Omit<ILine, 'id'>;
  sectionId: number;
  toStationId: number;
  vehicleStandardKey: VehicleKey;
  vehicleType: VehicleType;
  faresImagesData?: ShopFaresImageEntity[];
}

const Line: React.FC<LineProps> = ({
  arrivalCityName,
  departureCityName,
  fromStationId,
  lineProps: { from, to, code },
  sectionId,
  toStationId,
  vehicleStandardKey,
  vehicleType,
  faresImagesData,
}) => {
  const { t } = useTranslation();

  const formattedLine = code ? ` (${code})` : '';
  const vehicleTypeString = t(vehicleType === 'TRAIN' ? 'vehicle.RAIL_CAR' : 'vehicle.COACH');
  const { showModal } = useModal();

  const openModal = () =>
    showModal(
      <Timetable
        code={formattedLine}
        fromStationId={fromStationId}
        sectionId={sectionId}
        toStationId={toStationId}
        vehicleType={vehicleType}
        vehicleStandardKey={vehicleStandardKey}
        faresImagesData={faresImagesData}
      />,
      { size: 'big', title: t('route.detail') },
    );

  return (
    <ClickableDiv
      className="flex mb-0.5 w-fit-content text-primary-blue items-start lg:items-center group"
      onClick={openModal}
    >
      <Carrier vehicleStandardKey={vehicleStandardKey} />
      <span className="text-13 lg:text-14 group-hover:underline">{`${vehicleTypeString} ${
        departureCityName || from
      } — ${arrivalCityName || to}${formattedLine}`}</span>
    </ClickableDiv>
  );
};

export default Line;
