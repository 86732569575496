import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import Tooltip from '@/components/atoms/Tooltip';
import SupportModal from '@/components/connection/SupportModal';
import useModal from '@/components/modal/useModal';
import { mapActionPriceMainClasses } from '@/constants/actionPricesConsts';
import useMobileWidth from '@/hooks/useMobileWidth';
import { PlusBus } from '@/icons';

type BadgeVariant = 'supportConnection' | 'informational' | 'neutral';

const mapVariantToClasses: Record<BadgeVariant, string> = {
  informational: 'bg-neutral-white border border-secondary-redwarn text-secondary-redwarn',
  supportConnection: 'bg-primary-yellowlight4 text-secondary-orange font-bold',
  neutral: 'bg-primary-bluelight2 text-secondary-bluedark2 font-medium',
};

interface BadgeProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  variant?: BadgeVariant;
  actionColor?: string;
}

const Badge: React.FC<BadgeProps> = ({
  children,
  variant = 'informational',
  width,
  actionColor,
  className,
  ...props
}) => (
  <div
    className={classNames(
      'inline-flex text-12 px-1 gap-0.5 rounded-tiny p-0.5',
      className,
      actionColor
        ? `${mapActionPriceMainClasses[actionColor]} text-white`
        : mapVariantToClasses[variant],
    )}
    {...props}
  >
    {children}
  </div>
);

interface SupportConnectionBadgeProps {
  clearTooltip?: boolean;
  enableMobileView?: boolean;
}

export const SupportConnectionBadge: React.FC<SupportConnectionBadgeProps> = ({
  clearTooltip,
  enableMobileView,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobileWidth();
  const { showModal } = useModal();

  const handleOpenModal: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    showModal(<SupportModal />, { title: t('connections.info.support') });
  };

  return (
    <Tooltip
      disableHoverListener={clearTooltip}
      title={
        <div className="p-1">
          <Trans i18nKey="support.partnerCompany" />
        </div>
      }
    >
      <div onClick={isMobile && !clearTooltip ? handleOpenModal : undefined} className="flex">
        <Badge variant="supportConnection" className="flex items-center">
          <PlusBus className="w-2.5 h-2.5 fill-current" />
          {(!isMobile || !enableMobileView) && <Trans i18nKey="connections.info.support" />}
        </Badge>
      </div>
    </Tooltip>
  );
};

export default Badge;
