import { Trans } from 'next-i18next';
import React from 'react';
import { SeatClass, TicketType, VehicleKey } from '@web/shop-logic/dist/hooks/types';

interface SeatClassNameProps {
  seatClass: `${SeatClass}`;
  standard?: VehicleKey;
  type: TicketType;
}

const SeatClassName: React.FC<SeatClassNameProps> = ({ seatClass, standard, type }) => {
  if (seatClass === 'NO' && standard) return <Trans i18nKey={`standard.${standard}`} />;
  if (type === 'RJ_TIME') {
    return (
      <div>
        <Trans i18nKey="timeTicketType.flexi" />{' '}
        <Trans
          i18nKey={
            seatClass === 'TRAIN_2ND_CLASS'
              ? 'timeTicket.flexi.secondClass'
              : 'timeTicket.flexi.firstClass'
          }
        />
      </div>
    );
  }
  return <Trans i18nKey={`PricedSeatClass.${seatClass}`} />;
};

export default SeatClassName;
