import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { BookingContext, useConnectionRoute, useFlexiTickets, utils } from '@web/shop-logic';
import { Booking, ConnectionDirection } from '@web/shop-logic/dist/store/booking/types';
import useContextSelector from '@/hooks/useContextSelector';
import { getFirstParamValue, mapFareQuery } from '@/utils/routerUtils';

const getDirection = (route: string): ConnectionDirection | null => {
  if (!route) return 'there';
  if (route.includes('there')) return 'there';
  if (route.includes('back')) return 'back';
  return null;
};

const useCurrentBooking = () => {
  const { route, query } = useRouter();
  const direction = getDirection(getFirstParamValue(query.direction));

  const booking = useContextSelector(BookingContext, (c) => c.state.booking, {
    connection: {},
  } as Booking);
  const bookingItem = booking?.[direction];

  const connectionRoute = useConnectionRoute();
  const flexiTickets = useFlexiTickets();

  useEffect(() => {
    const { fromStationId, routeId, tariffs, toStationId } = mapFareQuery(query) || {};
    // route !== '/' - Forbid invocation for every ConnectionCard which is on index page)
    if (tariffs && fromStationId && toStationId && routeId && route !== '/') {
      const payload = { fromStationId, routeId, tariffs, toStationId };

      if (bookingItem?.type === 'RJ_SRO') setTimeout(() => connectionRoute.fetchSroRoutes(payload));
      setTimeout(() => connectionRoute.fetchRoute(payload));
      if (utils.isRegional(fromStationId, toStationId))
        setTimeout(() => flexiTickets.fetchRoute(payload, booking.connection.departureDate));
    }
  }, [query]);

  return { booking, bookingItem, direction, connectionRoute, flexiTickets };
};

export default useCurrentBooking;
