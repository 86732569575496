import { Trans } from 'next-i18next';
import React from 'react';
import Badge from '@/components/atoms/Badge';
import { Button } from '@/components/atoms/Button';
import ModalContext from '@/components/modal/ModalContext';
import useContextSelector from '@/hooks/useContextSelector';
import { PlusBus } from '@/icons';

const SupportModal: React.FC = () => {
  const hideAllModals = useContextSelector(ModalContext, (c) => c.hideAllModals);

  return (
    <div className="flex flex-col gap-1">
      <Badge variant="supportConnection">
        <PlusBus className="w-2.5 h-2.5 fill-current" />
        <Trans i18nKey="connections.info.support" />
      </Badge>
      <div>
        <Trans i18nKey="support.partnerCompany" />
      </div>
      <Button onClick={hideAllModals}>
        <Trans i18nKey="understand" />
      </Button>
    </div>
  );
};

export default SupportModal;
