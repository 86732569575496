import { isAfter } from 'date-fns';
import { ParsedUrlQuery } from 'querystring';
import { DateString, Tariff } from '@web/shop-logic/dist/hooks/types';
import { Section } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import { ConnectionSearchStation } from '@web/shop-logic/dist/hooks/useLocations';
import { TariffResponse } from '@web/shop-logic/dist/hooks/useTariffs';
import {
  SeatTicket,
  SroTicket,
  Tickets,
  TicketSection,
} from '@web/shop-logic/dist/hooks/useUserTickets';
import { ExtendedTicketProps } from '@/components/confirmationTicket/TicketProviders/TicketContext';
import { groupBy } from '@/utils/arrayUtils';

export const stringifyTicketsTariffs = (
  tariffs: TariffResponse[],
  selectedTariffs: Tariff[],
): string[] => {
  const tariffGroups = groupBy(selectedTariffs, (t) => t);
  return Array.from(tariffGroups.entries()).map(
    ([k, v]) => `${v.length}× ${tariffs.find((t) => t.key === k)?.value}`,
  );
};

export const getCurrentSeatNumber = (routeSections: TicketSection[]): DateString => {
  // estimatedArrivals = arrivalTime + delay
  const arrivals = routeSections.map((sectionProps) => sectionProps.section.estimatedArrivalTime);

  // Pokud je čas po odjezdu filtrujeme spoj, který už odjel, nefiltrujeme poslední
  const filteredArrivals = arrivals.filter((arrival, index) =>
    index !== arrivals.length - 1 ? isAfter(new Date(arrival), new Date()) : true,
  );

  const timeDiff = filteredArrivals.map((d) =>
    Math.abs(new Date().getTime() - new Date(d).getTime()),
  );

  return filteredArrivals[timeDiff.indexOf(Math.min(...timeDiff))];
};

export const getSeatOrSroTicket = (tickets: Tickets): SeatTicket[] | SroTicket[] => {
  if (tickets?.RJ_SEAT.length) return tickets.RJ_SEAT;
  if (tickets?.RJ_SRO.length) return tickets.RJ_SRO;
  return [];
};

export const sortTicketsById = (tickets: Tickets) => {
  if (!tickets) return undefined;
  // Rozšiřujeme ticket object o type (při merge do jednoho pole se informace o typu ztratí)
  const allTickets = [
    ...tickets.RJ_TIME.map((ticket) => ({ ...ticket, ticketType: 'RJ_TIME' })),
    ...tickets.RJ_SRO.map((ticket) => ({ ...ticket, ticketType: 'RJ_SRO' })),
    ...tickets.RJ_SEAT.map((ticket) => ({ ...ticket, ticketType: 'RJ_SEAT' })),
  ];
  // Zde by měl být sjednocený typ jízdenek namísto SeatTicket
  return (allTickets as SeatTicket[] | SroTicket[])?.sort(
    (a, b) => (a?.id || a?.sroTicketId) - (b?.id || b?.sroTicketId),
  );
};

export const mapTicketWithDirection = (tickets) =>
  tickets.map((ticket, index, arr) => {
    const isIdConsecutive = arr[index + 1]?.id - ticket.id === 1;
    const isIdConsecutiveBack = arr[index - 1]?.id - ticket.id === -1;

    // Časové jízdenky nemají routeSections
    const sectionsLength = ticket?.routeSections?.length || 0;

    // Časové jízdenky - neověřujeme (nikdy se nepárujou)
    if (sectionsLength === 0) return ticket;

    if (isIdConsecutiveBack) {
      // Časové jízdenky nemají routeSections
      const previousTicketRoutesLength = arr[index - 1]?.routeSections?.length || 0;

      // Časové jízdenky - neověřujeme (nikdy se nepárujou)
      if (previousTicketRoutesLength === 0) {
        return ticket;
      }

      const isArrCitySameAsDepCityInPreviousSecTicket =
        ticket.routeSections[sectionsLength - 1].section.arrivalCityName ===
        arr[index - 1].routeSections[0].section.departureCityName;
      const isDepCitySameAsArrivCityInPreviousSecTicket =
        ticket.routeSections[0].section.departureCityName ===
        arr[index - 1].routeSections[previousTicketRoutesLength - 1].section.arrivalCityName;

      if (
        isArrCitySameAsDepCityInPreviousSecTicket &&
        isDepCitySameAsArrivCityInPreviousSecTicket
      ) {
        const ticketCopy = JSON.parse(JSON.stringify(ticket));
        ticketCopy.isWayBack = true;
        return ticketCopy;
      }
    }

    if (isIdConsecutive) {
      // Časové jízdenky nemají routeSections
      const nextTicketRoutesLength = arr[index + 1]?.routeSections?.length || 0;

      // Časové jízdenky - neověřujeme (nikdy se nepárujou)
      if (nextTicketRoutesLength === 0) return ticket;

      const isArrCitySameAsDepCityInNextSecTicket =
        ticket.routeSections[sectionsLength - 1].section.arrivalCityName ===
        arr[index + 1].routeSections[0].section.departureCityName;
      const isDepCitySameAsArrivCityInNextSecTicket =
        ticket.routeSections[0].section.departureCityName ===
        arr[index + 1].routeSections[nextTicketRoutesLength - 1].section.arrivalCityName;
      if (isArrCitySameAsDepCityInNextSecTicket && isDepCitySameAsArrivCityInNextSecTicket) {
        const ticketCopy = JSON.parse(JSON.stringify(ticket));
        ticketCopy.isWayThere = true;
        return ticketCopy;
      }
    }
    return ticket;
  });

export const getStationLocationTypeAndId = (
  stations: ConnectionSearchStation[],
  stationName: string,
  isArrival = false,
) => {
  const station = stations?.find((s) => s.name.includes(stationName));
  if (isArrival) return { toLocationId: station?.id, toLocationType: 'STATION' };
  return { fromLocationId: station?.id, fromLocationType: 'STATION' };
};

export const handleInvoiceWindow = (invoiceData: string): void => {
  if (invoiceData) {
    const newWindow = window.open();
    if (newWindow) {
      newWindow.document.body.innerHTML = invoiceData;
      newWindow.print();
    }
  }
};

export const getTicketHeaderData = (routeSections: TicketSection[]) => ({
  departureCityName: routeSections[0].section.departureCityName,
  departureTime: routeSections[0].section.departureTime,
  arrivalCityName: routeSections[routeSections.length - 1].section.arrivalCityName,
  arrivalTime: routeSections[routeSections.length - 1].section.arrivalTime,
});

export const getTicketProps = (ticket): ExtendedTicketProps => ({
  addons: ticket?.addons || [],
  affiliateTicket: ticket?.affiliateTicket || null,
  cancelMoneyBackSum: ticket?.cancelMoneyBackSum || [],
  conditions: ticket?.conditions || null,
  customerActions: ticket.customerActions,
  delay: ticket?.delay || null,
  flexiType: ticket?.type || null,
  id: ticket?.id || ticket?.sroTicketId,
  passengersInfo:
    ticket?.passengersInfo ||
    (ticket?.tariff ? { passengers: [{ tariff: ticket.tariff.value }] } : null),
  paymentId: ticket?.paymentId || null,
  price: ticket.price,
  routeSections: ticket?.routeSections || [],
  seatClassKey: ticket?.seatClassKey || ticket?.seatClass,
  state: ticket.state,
  surcharge: ticket?.surcharge || null,
  ticketCode: ticket?.ticketCode || null,
  transfersInfo: ticket?.transfersInfo || null,
  unpaid: ticket.unpaid,
  wheelChairPlatformOrderPossible: ticket.wheelChairPlatformOrderPossible,
  wheelChairPlatformOrdered: ticket.wheelChairPlatformOrdered,
});

export const isCroatiaLine = (lineGroupCode: string): boolean => 'RJ_JADRAN' === lineGroupCode;

export const isUkTransfer = (section: Section, nextSection?: Section): boolean =>
  (section.vehicleStandardKey === 'YELLOW' &&
    nextSection?.vehicleStandardKey === 'UKRAINIAN_RAILWAYS') ||
  (section.vehicleStandardKey === 'UKRAINIAN_RAILWAYS' &&
    nextSection?.vehicleStandardKey === 'YELLOW');

export const getFirstTicketFromQuery = (query: ParsedUrlQuery) => {
  const firstEntry = Object.entries(query).filter(([key]) =>
    ['RJ_SEAT', 'RJ_SRO', 'RJ_TIME'].includes(key),
  )[0];
  if (!firstEntry?.length) return null;
  return firstEntry;
};
