import { useRouter } from 'next/router';
import { useHeaders } from '@web/shop-logic';
import { Currency } from '@web/shop-logic/dist/hooks/types';
import { formatPrice as formatPriceFnc } from '@/components/atoms/Price';

interface UsePrice {
  formatPrice: (price: number) => string;
}

const usePrice = (customCurrency?: Currency): UsePrice => {
  const { locale } = useRouter();
  const { currency: shopCurrency } = useHeaders();

  const currency = customCurrency ?? shopCurrency;

  const formatPrice = (price: number) => formatPriceFnc(price, currency, locale);

  return { formatPrice };
};

export default usePrice;
