import React from 'react';
import { VehicleKey } from '@web/shop-logic/dist/hooks/types';
import {
  EconomyBus,
  IdsLogo,
  ObbLogo,
  RjLogo,
  UkrainianRailways,
  WestbahnLogo,
  Gepard,
  UnitedBuses,
} from '@/icons';

const iconsStyles = 'w-2.5 h-2.5 lg:w-3 lg:h-3 inline-flex mr-0.75';

interface CarrierProps {
  vehicleStandardKey: VehicleKey;
}

const Carrier: React.FC<CarrierProps> = ({ vehicleStandardKey }) => {
  switch (vehicleStandardKey) {
    case 'ECONOMY':
    case 'ECONOMY_PLATFORM':
      return <EconomyBus className={iconsStyles} />;
    case 'SAD':
      return null;
    case 'WEST_BAHN':
      return <WestbahnLogo className={iconsStyles} viewBox="0 0 49 35" />;
    case 'IDS_JMK':
      return <IdsLogo className={iconsStyles} viewBox="0 0 47 33" />;
    case 'OBB':
      return <ObbLogo className={iconsStyles} />;
    case 'UKRAINIAN_RAILWAYS':
      return <UkrainianRailways className={iconsStyles} viewBox="0 0 185 66" />;
    case 'GEPARD_EXPRESS':
      return <Gepard className={iconsStyles} fill="#E9E611" />;
    case 'UNITED_BUSES':
      return <UnitedBuses className={iconsStyles} />;
    default:
      return <RjLogo className={iconsStyles} />;
  }
};
export default Carrier;
