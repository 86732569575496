export enum ActionPricesVariants {
  GREEN = 'green',
  ORANGE = 'orange',
  BLUE = 'blue',
  PINK = 'pink',
  YELLOW = 'yellow',
  PURPLE = 'purple',
}

export const mapActionPriceMainClasses = {
  [ActionPricesVariants.GREEN]: 'bg-secondary-green',
  [ActionPricesVariants.ORANGE]: 'bg-secondary-orange',
  [ActionPricesVariants.BLUE]: 'bg-primary-blue',
  [ActionPricesVariants.PINK]: 'bg-secondary-pinkdark',
  [ActionPricesVariants.YELLOW]: 'bg-primary-yellow',
  [ActionPricesVariants.PURPLE]: 'bg-secondary-purple',
};

export const mapActionPriceSecondaryClasses = {
  [ActionPricesVariants.GREEN]: 'bg-secondary-greenlight2',
  [ActionPricesVariants.ORANGE]: 'bg-secondary-orangelight',
  [ActionPricesVariants.BLUE]: 'bg-primary-bluelight3',
  [ActionPricesVariants.PINK]: 'bg-secondary-pinklight',
  [ActionPricesVariants.YELLOW]: 'bg-primary-yellowlight3',
  [ActionPricesVariants.PURPLE]: 'bg-secondary-purplelight2',
};

export const mapActionPriceBorderClasses = {
  [ActionPricesVariants.GREEN]: 'border-secondary-green',
  [ActionPricesVariants.ORANGE]: 'border-secondary-orange',
  [ActionPricesVariants.BLUE]: 'border-primary-blue',
  [ActionPricesVariants.PINK]: 'border-secondary-pinkdark',
  [ActionPricesVariants.YELLOW]: 'border-primary-yellow',
  [ActionPricesVariants.PURPLE]: 'border-secondary-purple',
};
